<template>
  <DrupalLink
    v-if="(to || isEditing) && total === 1"
    :to="to"
    class="home-satge-teaser-single group relative col-start-1 col-end-[-1] block aspect-[9/16] h-full w-full overflow-hidden md:aspect-16/9 xl:col-start-2 xl:col-end-[-2] xl:mx-0"
    :class="uniqueCssClass"
  >
    <MediaImage
      v-if="media?.__typename === 'MediaImage' || rokkaHash"
      v-blokkli-droppable:field_media_image
      v-bind="media?.__typename === 'MediaImage' ? media : { rokkaHash }"
      class="relative h-full w-full overflow-hidden"
      img-class="w-full h-full object-cover"
      eager
      preload
      :image-style="imageStyleFull"
    />
    <MediaVideo
      v-if="media?.__typename === 'MediaVideo'"
      v-blokkli-droppable:field_media_video
      :video="media.video"
      video-class="object-cover"
      :poster="media.poster"
      autoplay
      muted
      loop
      class="pointer-events-none"
    />
    <div
      class="absolute bottom-[4%] left-[9%] flex h-auto w-full max-w-[210px] flex-row md:bottom-[7%] md:left-auto md:right-[8.4%] md:max-w-[254px] md:flex-row-reverse"
    >
      <div
        ref="headlineDiv"
        v-blokkli-editable:field_image_headline
        class="typo-title-1 uppercase leading-6 text-white decoration-2 underline-offset-4 group-hover:text-white/85 group-hover:underline group-focus-visible:underline md:text-2xl"
      >
        {{ headline }}
        <span ref="headlinePadding"></span>
      </div>
    </div>
  </DrupalLink>
  <DrupalLink
    v-else-if="to || isEditing"
    :to="to"
    class="group sticky top-72 flex h-[calc(100dvh-72px)] flex-col justify-center px-28 md:relative md:top-0 md:h-auto md:px-0"
    :class="gridClasses + ' ' + uniqueCssClass"
  >
    <MediaImage
      v-if="media || rokkaHash"
      v-blokkli-droppable:field_media_image
      v-bind="media || { rokkaHash }"
      class="relative w-full overflow-hidden"
      img-class="w-full"
      eager
      preload
      :image-style="imageStyle"
      :class="
        total === 3 && actualIndex === 2
          ? 'aspect-[3/4] lg:aspect-[2/3]'
          : 'aspect-[3/4]'
      "
    />
    <div
      v-blokkli-editable:field_image_headline
      class="typo-title-1 bg-grey-light-03 pb-30 pt-30 uppercase text-grey-dark-02 decoration-1 underline-offset-4 group-hover:text-black group-hover:underline group-focus-visible:underline md:pb-0"
      :class="{
        'home-stage-teaser-headline-pull': total === 2 && actualIndex === 1,
      }"
    >
      {{ headline }}
    </div>
  </DrupalLink>
</template>

<script lang="ts" setup>
import type { ParagraphHomeStageTeaserFragment } from '#build/graphql-operations'

export type HomeStageTeaserProps = {
  headline?: string
  link?: ParagraphHomeStageTeaserFragment['link'] | string
  media?: ParagraphHomeStageTeaserFragment['media']
  rokkaHash?: string
}

const rootElement = ref<Element>()
const headlineDiv = ref<HTMLDivElement>()
const headlinePadding = ref<HTMLSpanElement>()

const props = defineProps<HomeStageTeaserProps>()

const { isEditing, index, siblings } = defineBlokkli({
  bundle: 'home_stage_teaser',
})

const insideBlokkli = computed(
  // also check that siblings.value.length is greater than 0 since it always includes the element itself when inside a Blökkli context.
  () => siblings !== undefined && siblings.value.length > 0,
)

const htmlSiblings = computed(() => {
  const htmlElement = rootElement.value

  if (htmlElement && htmlElement.parentElement) {
    return Array.from(htmlElement.parentElement.children)
  } else {
    return undefined
  }
})

const actualIndex = computed<number | undefined>(() => {
  if (insideBlokkli.value) {
    return index.value
  } else if (htmlSiblings.value && rootElement.value) {
    return htmlSiblings.value.indexOf(rootElement.value)
  } else {
    return 0
  }
})

// The total amount of teasers.
const total = computed(() => {
  if (insideBlokkli.value) {
    return siblings.value.length
  } else {
    return htmlSiblings.value?.length ?? 0
  }
})

// The link target.
const to = computed(() =>
  typeof props.link === 'string' ? props.link : props.link?.uri?.path,
)

const imageStyleFull = defineImageStyleOris({
  type: 'pictures',
  pictures: {
    sm: {
      width: 720,
      aspectRatio: 9 / 16,
    },
    md: {
      width: 960,
      aspectRatio: 16 / 9,
    },
    lg: {
      width: 1135,
      aspectRatio: 16 / 9,
    },
    xl: {
      width: 1296,
      aspectRatio: 16 / 9,
    },
  },
})

const imageStyleSmall = defineImageStyle({
  type: 'pictures',
  pictures: {
    sm: {
      width: 685,
      aspectRatio: 3 / 4,
    },
    md: {
      width: 280,
      aspectRatio: 3 / 4,
    },
    lg: {
      width: 155,
      aspectRatio: 3 / 4,
    },
    xl: {
      width: 166,
      aspectRatio: 3 / 4,
    },
    xxl: {
      width: 196,
      aspectRatio: 3 / 4,
    },
  },
})

const imageStyleMedium = defineImageStyle({
  type: 'pictures',
  pictures: {
    sm: {
      width: 685,
      aspectRatio: 3 / 4,
    },
    md: {
      width: 280,
      aspectRatio: 3 / 4,
    },
    lg: {
      width: 261,
      aspectRatio: 2 / 3,
    },
    xxl: {
      width: 306,
      aspectRatio: 2 / 3,
    },
  },
})

const imageStyleLarge = defineImageStyle({
  type: 'pictures',
  pictures: {
    sm: {
      width: 685,
      aspectRatio: 3 / 4,
    },
    md: {
      width: 430,
      aspectRatio: 3 / 4,
    },
  },
})

const imageStyle = computed(() => {
  if (total.value === 1) {
    return imageStyleFull
  } else if (total.value === 2) {
    // todo: probably not correct
    return imageStyleLarge
  } else if (total.value === 3) {
    if (actualIndex.value === 0) {
      return imageStyleLarge
    } else if (actualIndex.value === 1) {
      return imageStyleSmall
    }

    return imageStyleMedium
  }
})

const gridClasses = computed(() => {
  // Handle case with two teasers.
  if (total.value === 2) {
    if (actualIndex.value === 0) {
      return 'col-start-2 lg:col-start-3 col-span-4 lg:col-span-3 self-start md:mt-112'
    }
    return 'col-start-8 col-span-6 lg:col-span-4 lg:col-start-8 md:mt-[172px] md:mb-112'
  } else if (total.value === 3) {
    // Handle case with three teasers.

    if (actualIndex.value === 0) {
      return 'col-start-2 lg:col-start-3 col-span-4 md:my-112'
    } else if (actualIndex.value === 1) {
      return 'col-start-6 col-span-4 lg:col-start-8 lg:col-span-2'
    }

    return 'col-start-10 col-span-4 lg:col-start-10 lg:col-span-3'
  }

  // We should not end up here because only two or three teasers are supported.
  // A single teaser is handled using separate markup.
})

const instance = getCurrentInstance()
const uniqueCssClass = computed(
  () => 'paragraph-home-stage-teaser-' + (instance?.uid ?? 0),
)

const fixHeadlineDivWidth = () => {
  if (!headlineDiv.value || !headlinePadding.value) {
    return
  }

  // fix size and alignment of headline div
  // We want the text to be left aligned, but it should stick to the right border.
  //
  //                        | <- space to right picture edge -> |
  // ------------------------
  // | PROPILOT X KERMIT    |
  // | EDITION              |
  // ------------------------
  //
  //            ||
  //           \||/
  //            \/
  //                        | <- space to right picture edge -> |
  //                             stays the same
  //    ---------------------
  //    | PROPILOT X KERMIT |
  //    | EDITION           |
  //    ---------------------

  headlineDiv.value.style.removeProperty('width')
  const initialHeight = headlineDiv.value.getBoundingClientRect().height
  const initialHeadlinePadding =
    headlinePadding.value?.getBoundingClientRect().x -
    headlineDiv.value.getBoundingClientRect().x

  // With this for loop we shrink the size of the headlineDiv so that it just encloses the content.
  // width: min-content; changes the word wrapping which we want to avoid.
  for (let i = headlineDiv.value.getBoundingClientRect().width; i > 0; i--) {
    headlineDiv.value.style.width = `${i}px`

    // The width overflows (because no more word wrapping is possible)
    if (
      headlineDiv.value.getBoundingClientRect().width <
      headlineDiv.value.scrollWidth
    ) {
      break
    }

    // The headlineDiv height changed. Word wrapping changed and introduced a new line.
    if (headlineDiv.value.getBoundingClientRect().height !== initialHeight) {
      break
    }

    // The headlinePadding changed its x location, which means that a new word got wrapped to the next line.
    if (
      headlinePadding.value?.getBoundingClientRect().x -
        headlineDiv.value.getBoundingClientRect().x !==
      initialHeadlinePadding
    ) {
      break
    }
  }

  // Reset the headlineDiv width to the state before any of the unwanted changed occured.
  // This ensure the width tightly encloses the content without changed the word wrapping.
  headlineDiv.value.style.width = `${parseInt(headlineDiv.value.style.width) + 2}px`
}

const { isMobile } = useViewport()
watch(isMobile, fixHeadlineDivWidth)

const setRootElement = () => {
  rootElement.value =
    document.querySelector(`.${uniqueCssClass.value}`) ?? undefined
}

onMounted(() => {
  // :ref was always undefined if set on DrupalLink, that's why we use docuemnt.querySelector here
  setRootElement()

  fixHeadlineDivWidth()
})
</script>

<style lang="postcss">
.home-stage-teaser-headline-pull {
  /*
    This magic calculation makes sure the headline of the second teaser fits
    excatly inside two layout grid columns.
    The width of 100% is the width of the teaser.
  */
  @screen md {
    @apply absolute bottom-0 right-full;
    width: calc((100% - var(--layout-grid-gap)) / 2 + var(--layout-grid-gap));
    padding-right: var(--layout-grid-gap);
  }
}
</style>
